<template>
  <div class="product-filter box1">
    <div action="#" class="form mb-0">
      <h4>Filter By</h4>
      <div class="filter-options price-filter">
        <h6>Price</h6>
        <input type="range" class="form-control-range mb-3" v-model="price" :min="minPrice" :max="maxPrice"
          @change="priceChanged" data-toggle="tooltip" data-placement="top" :title="price">

        <ul class="list">
          <li><input type="text" class="form-control" v-model="minPrice" readonly></li>
          <li><span>to</span></li>
          <li><input type="text" class="form-control" v-model="price" readonly></li>
        </ul>

      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#series-filter" role="button" aria-expanded="true"
          aria-controls="series-filter">
          Product series
        </h6>
        <div class="wrapper collapse show" id="series-filter">
          <VueMultiselect v-model="series" :options="serieses" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select serieses" :searchable="false" selectLabel="" deselectLabel=""
            @input="seriesChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#width-filter" role="button" aria-expanded="true"
          aria-controls="width-filter">
          Width of the Product (mm)
        </h6>
        <div class="wrapper collapse show" id="width-filter">
          <VueMultiselect v-model="width" :options="widths" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select widths" :searchable="false" selectLabel="" deselectLabel=""
            @input="widthChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#height-filter" role="button" aria-expanded="true"
          aria-controls="height-filter">
          Height of the Product (mm)
        </h6>
        <div class="wrapper collapse show" id="height-filter">
          <VueMultiselect v-model="height" :options="heights" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select heights" :searchable="false" selectLabel="" deselectLabel=""
            @input="heightChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#depth-filter" role="button" aria-expanded="true"
          aria-controls="depth-filter">
          Depth of the Product (mm)
        </h6>
        <div class="wrapper collapse show" id="depth-filter">
          <VueMultiselect v-model="depth" :options="depths" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select depths" :searchable="false" selectLabel="" deselectLabel=""
            @input="depthChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#efficiency-filter" role="button" aria-expanded="true"
          aria-controls="efficiency-filter">
          Energy Efficiency
        </h6>
        <div class="wrapper collapse show" id="efficiency-filter">
          <VueMultiselect v-model="energyEfficiency" :options="energyEfficiencies" :multiple="true"
            :close-on-select="true" open-direction="bottom" placeholder="Select energy efficiencies" :searchable="false"
            selectLabel="" deselectLabel="" @input="energyEfficiencyChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#installation-filter" role="button" aria-expanded="true"
          aria-controls="installation-filter">
          Installation Type
        </h6>
        <div class="wrapper collapse show" id="installation-filter">
          <VueMultiselect v-model="installation" :options="installations" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select installation types" :searchable="false" selectLabel=""
            deselectLabel="" @input="installationChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#control-filter" role="button" aria-expanded="true"
          aria-controls="type-filter">
          Type of control
        </h6>
        <div class="wrapper collapse show" id="control-filter">
          <VueMultiselect v-model="controlType" :options="controlTypes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select control types" :searchable="false" selectLabel=""
            deselectLabel="" @input="controlTypeChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#connectivity-filter" role="button" aria-expanded="true"
          aria-controls="type-filter">
          Connectivity type
        </h6>
        <div class="wrapper collapse show" id="connectivity-filter">
          <VueMultiselect v-model="connectivity" :options="connectivities" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select connectivity types" :searchable="false" selectLabel=""
            deselectLabel="" @input="connectivityChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#noise-filter" role="button" aria-expanded="true"
          aria-controls="noise-filter">
          Noise level
        </h6>
        <div class="wrapper collapse show" id="noise-filter">
          <VueMultiselect v-model="noise" :options="noiseLevels" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select noise levels" :searchable="false" selectLabel=""
            deselectLabel="" @input="noiseChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#light-filter" role="button" aria-expanded="true"
          aria-controls="light-filter">
          Light Type
        </h6>
        <div class="wrapper collapse show" id="light-filter">
          <VueMultiselect v-model="lightType" :options="lightTypes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select Light Types" :searchable="false" selectLabel="" deselectLabel=""
            @input="lightTypeChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#operating-mode-filter" role="button"
          aria-expanded="true" aria-controls="operating-mode-filter">
          Operating mode
        </h6>
        <div class="wrapper collapse show" id="operating-mode-filter">
          <VueMultiselect v-model="operatingMode" :options="operatingModes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select operating modes" :searchable="false" selectLabel=""
            deselectLabel="" @input="operatingModeChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#feature-filter" role="button" aria-expanded="true"
          aria-controls="feature-filter">
          Key Features
        </h6>
        <div class="wrapper collapse show" id="feature-filter">
          <ul class="list">
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="remoteControl" id="feature-0" @change="remoteControlChanged" />
                <label for="feature-0">Remote control</label>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import ProductService from "@/services/product.service";
import VueMultiselect from "vue-multiselect";

export default {
  name: "ExtractorFilters",
  data: function () {
    return {
      widths: [],
      serieses: [],
      noiseLevels: [],
      colors: [],
      heights: [],
      depths: [],
      connectivities: [],
      controlTypes: [],
      energyEfficiencies: [],
      installations: [],
      lightTypes: [],
      operatingModes: [],

      width: [],
      noise: [],
      color: [],
      height: [],
      depth: [],
      connectivity: [],
      controlType: [],
      energyEfficiency: [],
      installation: [],
      lightType: [],
      operatingMode: [],
      remoteControl: false,
      minPrice: 0,
      maxPrice: 0,

      price: 0,
      series: [],
    };
  },
  components: {
    VueMultiselect,
  },
  watch: {
    // call again the method if the route changes
    selectedBrands: function () {
      this.bindValues();
    },
  },
  mounted() {
    this.bindValues();
    if (this.selectedFilters.category == this.selectedSubCategory.id) {
      this.existingFilters();
    }
  },
  computed: {
    selectedSubCategory() {
      return this.$store.state.subCategory.selectedSubCategory;
    },
    selectedBrands() {
      return this.$store.state.brand.selectedBrands;
    },
    selectedFilters() {
      return this.$store.state.filters.selectedFilters;
    }
  },
  methods: {
    bindValues() {
      ProductService.minMaxPriceFor(
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.minPrice = response.price__min;
        this.maxPrice = response.price__max;
        this.price = response.price__max;
        if (this.selectedFilters.price__lte) {
          this.price = Number(this.selectedFilters.price__lte);
        }
      });
      ProductService.valuesFor(
        "attr_width",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.widths = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_noise_marketing",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.noiseLevels = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_col_main",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.colors = response.filter((item) => !["", null].includes(item));
      });

      ProductService.valuesFor(
        "series",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.serieses = response.filter((item) => !["", null].includes(item));
      });

      ProductService.valuesFor(
        "attr_height",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.heights = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_depth",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.depths = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_energy_class_2010",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.energyEfficiencies = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_homeconnect_connectivity",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.connectivities = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_inst_type",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.installations = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_control_setting",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.controlTypes = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_lamps_type",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.lightTypes = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_operating_mode",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.operatingModes = response.filter(
          (item) => !["", null].includes(item)
        );
      });
    },

    priceChanged() {
      let filters = { price__lte: this.price };
      this.$emit("changeFilters", filters);
    },

    widthChanged() {
      let filters = {
        attr_width__in: _.map(this.width, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    noiseChanged() {
      let filters = {
        attr_noise_marketing__in: _.map(this.noise, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    seriesChanged() {
      let filters = {
        series__in: _.map(this.series, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    colorChanged() {
      let filters = {
        attr_col_main__in: _.map(this.color, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    heightChanged() {
      let filters = {
        attr_height__in: _.map(this.height, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    depthChanged() {
      let filters = {
        attr_depth__in: _.map(this.depth, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    installationChanged() {
      let filters = {
        attr_inst_type__in: _.map(this.installation, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    energyEfficiencyChanged() {
      let filters = {
        attr_energy_class_2010__in: _.map(this.energyEfficiency, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    controlTypeChanged() {
      let filters = {
        attr_control_setting__in: _.map(this.controlType, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    connectivityChanged() {
      let filters = {
        attr_homeconnect_connectivity__in: _.map(this.connectivity, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    lightTypeChanged() {
      let filters = {
        attr_lamps_type__in: _.map(this.lightType, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    operatingModeChanged() {
      let filters = {
        attr_operating_mode__in: _.map(this.operatingMode, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    remoteControlChanged() {
      let filters = {
        attr_remote_control: this.remoteControl ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },

    existingFilters() {
      this.remoteControl = this.selectedFilters.attr_remote_control;

      if (this.selectedFilters.attr_width__in) {
        let sortedWidths = [];
        let unsortedWidths = this.selectedFilters.attr_width__in.split('","');
        unsortedWidths.map((width) => {
          width = width.replaceAll('"', '')
          sortedWidths.push(width)
        });
        this.width = sortedWidths;
      }

      if (this.selectedFilters.attr_noise_marketing__in) {
        let sortedNoises = [];
        let unsortedNoises = this.selectedFilters.attr_noise_marketing__in.split('","');
        unsortedNoises.map((noise) => {
          noise = noise.replaceAll('"', '')
          sortedNoises.push(noise)
        });
        this.noise = sortedNoises;
      }

      if (this.selectedFilters.series__in) {
        let sortedSeries = [];
        let unsortedSeries = this.selectedFilters.series__in.split('","');
        unsortedSeries.map((series) => {
          series = series.replaceAll('"', '')
          sortedSeries.push(series)
        });
        this.series = sortedSeries;
      }

      if (this.selectedFilters.attr_col_main__in) {
        let sortedColors = [];
        let unsortedColors = this.selectedFilters.attr_col_main__in.split(
          '","'
        );
        unsortedColors.map((color) => {
          color = color.replaceAll('"', "");
          sortedColors.push(color);
        });
        this.color = sortedColors;
      }

      if (this.selectedFilters.attr_height__in) {
        let sortedHeights = [];
        let unsortedHeights = this.selectedFilters.attr_height__in.split('","');
        unsortedHeights.map((height) => {
          height = height.replaceAll('"', "");
          sortedHeights.push(height);
        });
        this.height = sortedHeights;
      }

      if (this.selectedFilters.attr_depth__in) {
        let sortedDepths = [];
        let unsortedDepths = this.selectedFilters.attr_depth__in.split('","');
        unsortedDepths.map((depth) => {
          depth = depth.replaceAll('"', "");
          sortedDepths.push(depth);
        });
        this.depth = sortedDepths;
      }

      if (this.selectedFilters.attr_inst_type__in) {
        let sortedInstallations = [];
        let unsortedInstallations = this.selectedFilters.attr_inst_type__in.split(
          '","'
        );
        unsortedInstallations.map((installation) => {
          installation = installation.replaceAll('"', "");
          sortedInstallations.push(installation);
        });
        this.installation = sortedInstallations;
      }

      if (this.selectedFilters.attr_energy_class_2010__in) {
        let sortedEnergies = [];
        let unsortedEnergies = this.selectedFilters.attr_energy_class_2010__in.split(
          '","'
        );
        unsortedEnergies.map((energy) => {
          energy = energy.replaceAll('"', "");
          sortedEnergies.push(energy);
        });
        this.energyEfficiency = sortedEnergies;
      }

      if (this.selectedFilters.attr_control_setting__in) {
        let sortedControls = [];
        let unsortedControls = this.selectedFilters.attr_control_setting__in.split(
          '","'
        );
        unsortedControls.map((control) => {
          control = control.replaceAll('"', "");
          sortedControls.push(control);
        });
        this.controlType = sortedControls;
      }

      if (this.selectedFilters.attr_homeconnect_connectivity__in) {
        let sortedConnectivities = [];
        let unsortedConnectivities = this.selectedFilters.attr_homeconnect_connectivity__in.split(
          '","'
        );
        unsortedConnectivities.map((connectivity) => {
          connectivity = connectivity.replaceAll('"', "");
          sortedConnectivities.push(connectivity);
        });
        this.connectivity = sortedConnectivities;
      }

      if (this.selectedFilters.attr_operating_mode__in) {
        let sortedModes = [];
        let unsortedModes = this.selectedFilters.attr_operating_mode__in.split(
          '","'
        );
        unsortedModes.map((mode) => {
          mode = mode.replaceAll('"', "");
          sortedModes.push(mode);
        });
        this.operatingMode = sortedModes;
      }

      if (this.selectedFilters.attr_lamps_type__in) {
        let sortedLights = [];
        let unsortedLights = this.selectedFilters.attr_homeconnect_connectivity__in.split(
          '","'
        );
        unsortedLights.map((light) => {
          light = light.replaceAll('"', "");
          sortedLights.push(light);
        });
        this.lightType = sortedLights;
      }
    }
  },
};
</script>

<style scoped></style>